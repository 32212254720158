/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://isb73bj361.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        },
        {
            "name": "AdminSC",
            "endpoint": "https://tsi5poksa3.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        }
    ],
    "aws_cognito_identity_pool_id": "us-east-1:1a200aeb-80b0-4979-bdcb-b5cd37cf58f0",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_ZhoJ90XpU",
    "aws_user_pools_web_client_id": "tbb4b7k4ge4dhepn3flt3naiv",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": "8",
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "PHONE_NUMBER"
    ],
    "aws_user_files_s3_bucket": "attachmentsadminsc181543-dev",
    "aws_user_files_s3_bucket_region": "us-east-1",
    "geo": {
        "amazon_location_service": {
            "region": "us-east-1",
            "maps": {
                "items": {
                    "scMap-dev": {
                        "style": "VectorEsriStreets"
                    }
                },
                "default": "scMap-dev"
            }
        }
    },
    "aws_cognito_login_mechanism": [
        "PREFERRED_USERNAME"
    ]
};


export default awsmobile;

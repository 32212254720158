import React, { useState, useEffect, useRef, useMemo } from 'react';

// Ruteador
import { Route, Link, useHistory } from "react-router-dom";

// LoadPanel
import { LoadPanel } from 'devextreme-react/load-panel';

import GroupCaption from '../GroupCaption/GroupCaption.js';

//Form
import Form, { GroupItem, Label, TabbedItem, TabPanelOptions, Tab, EmptyItem, SimpleItem } from 'devextreme-react/form';

import DropDownBox from 'devextreme-react/drop-down-box';

import { Auth, API, Storage } from 'aws-amplify';

// Toast
import { Toast } from 'devextreme-react/toast';

// Grid
import DataGrid, {
    Column, FilterRow, HeaderFilter, FilterPanel, FilterBuilderPopup,
    GroupPanel, Grouping, SearchPanel, Sorting, Editing, Lookup, Toolbar, Item, RequiredRule,
    Scrolling, Pager, Paging, Summary, TotalItem, StateStoring, ColumnChooser, Format, Selection,
    Export, ColumnChooserSearch, ColumnChooserSelection
} from 'devextreme-react/data-grid';

// Iconos
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsRotate } from "@fortawesome/pro-solid-svg-icons";
import { faWarehouse } from "@fortawesome/pro-solid-svg-icons";
import { faBoxCheck } from "@fortawesome/pro-solid-svg-icons";
import { faFileExcel } from "@fortawesome/pro-solid-svg-icons";
import { faFileChartColumn } from "@fortawesome/pro-duotone-svg-icons";

import dxDateBox from "devextreme/ui/date_box";

import dxNumberBox from "devextreme/ui/number_box";

import Lambda from 'aws-sdk/clients/lambda';

import '../ListStyle.css';

import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
import { exportDataGrid } from 'devextreme/excel_exporter'

dxDateBox.defaultOptions({
    options: {
        dateSerializationFormat: "yyyy-MM-dd"
    }
});

dxNumberBox.defaultOptions({
    options: {
        step: 0
    }
});

function isNotEmpty(value) {
    return value !== undefined && value !== null && value !== '';
}

export default function POSuggestionsReport(props) {
    const history = useHistory();

    const [visibleLoadPanel, setVisibleLoadPanel] = useState(false);
    const [poSuggestionsReportCfg, setPOSuggestionsReportCfg] = useState(props.poSuggestionsReportCfg);
    const [ejercicios, setEjercicios] = useState([]);
    const [periodosVenta, setPeriodosVenta] = useState([
        {
            name: 'Pasado',
            value: 'Pasado'
        },
        {
            name: 'Futuro',
            value: 'Futuro'
        }
    ]);
    const [niveles, setNiveles] = useState([
        {
            name: 'Concentrado',
            value: 'Concentrado'
        },
        {
            name: 'Desglosado',
            value: 'Desglosado'
        }
    ]);
    const [periodos, setPeriodos] = useState([]);
    const [proveedores, setProveedores] = useState([]);
    const [provNombre, setProvNombre] = useState({});
    const [artDescripcion, setArtDescripcion] = useState({});
    const [plazasentrega, setPlazasEntrega] = useState([]);

    const [proveedoresSeleccionados, setProveedoresSeleccionados] = useState([]);

    const [tabSelectedIndex, setTabSelectedIndex] = useState(0);

    const dateFormatYYYYDDMM = React.useCallback(() => {
        var date = new Date();

        var year = date.getFullYear().toString();
        var month = date.getMonth() + 1;
        var day = date.getDate().toString();

        if (month < 10)
            month = '0' + month.toString();
        else
            month = month.toString();

        return (
            year + "-" + month + "-" + day
        );
    }, []);

    const [reportPayload, setReportPayload] = useState({
        header: {
            Proveedor: '',
            Nivel: 'Concentrado',
            PeriodosVenta: 'Pasado'
        },
        titles: {
            PeriodoNombre: '.',
            Periodo1Nombre: '.',
            Periodo2Nombre: '.',
            Periodo3Nombre: '.',
            Periodo_ant_Nombre: '.',
            Periodo1_ant_Nombre: '.',
            Periodo2_ant_Nombre: '.',
            Periodo3_ant_Nombre: '.',
            PFuturoNombre1: '.',
            PFuturoNombre2: '.',
            PFuturoNombre3: '.',
            PFuturoNombre4: '.',
            PFuturoNombre5: '.',
            PFuturoNombre6: '.',
            PFuturoNombre7: '.'
        },
        detail: [],
        detailWHS: [],
        po: []
    });

    const [allowFuture, setAllowFuture] = useState((new Date().getMonth() + 1 >= 7 ? true : false));

    const [future1, setFuture1] = useState(false);
    const [future2, setFuture2] = useState(false);
    const [future3, setFuture3] = useState(false);
    const [future4, setFuture4] = useState(false);
    const [future5, setFuture5] = useState(false);
    const [future6, setFuture6] = useState(false);
    const [future7, setFuture7] = useState(false);

    //Ref para el form
    const [formReporteRef, setFormReporteRef] = useState(React.createRef());

    //Ref para el grid Sugerencia por plaza
    const [gridSugerenciaRef, setGridSugerenciaRef] = useState(React.createRef());

    //Ref para el grid Sugerencia por almacen
    const [gridSugerenciaAlmRef, setGridSugerenciaAlmRef] = useState(React.createRef());

    const filterBuilderPopupPosition = {
        of: window,
        at: 'top',
        my: 'top',
        offset: { y: 10 },
    };

    const allowedPageSizes = [5, 10, 15, 20, 'all'];

    const [autoExpandAll, setAutoExpandAll] = useState(true);

    const [visibleToast, setVisibleToast] = useState(false);
    const [messageToast, setMessageToast] = useState('');
    const [typeToast, setTypeToast] = useState('success');

    function getOrderDay(rowData) {
        return (new Date(rowData.OrderDate)).getDay();
    }

    const filterBuilder = {
        customOperations: [{
            name: 'weekends',
            caption: 'Weekends',
            dataTypes: ['date'],
            icon: 'check',
            hasValue: false,
            calculateFilterExpression: () => [[getOrderDay, '=', 0], 'or', [getOrderDay, '=', 6]],
        }],
        allowHierarchicalFields: true,
    };

    async function getProveedor(sqlFilter) {
        let apiName = 'AdminSC';
        let path = '/getcatalogo';

        var tmpProvNombre = {};

        let myInit = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
            },
            queryStringParameters: {
                type: 'ProveedorCompra',
                username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username,
                sqlFilter: ''
            }
        }

        var proveedoresGet = await API.get(apiName, path, myInit);

        // Creamos el objeto con los nombres de los proveedores
        for (var proveedoresGetIndex in proveedoresGet) {
            var singleProveedorGet = proveedoresGet[proveedoresGetIndex];

            tmpProvNombre[singleProveedorGet.value.trim()] = singleProveedorGet.name;
        }

        setProveedores(proveedoresGet);
        setProvNombre(tmpProvNombre);
    };

    async function getArticulo(sqlFilter) {
        let apiName = 'AdminSC';
        let path = '/getcatalogo';

        var tmpArtDescripcion = {};

        let myInit = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
            },
            queryStringParameters: {
                type: 'ArticuloPO',
                username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username,
                sqlFilter: ''
            }
        }

        var articulosGet = await API.get(apiName, path, myInit);

        // Creamos el objeto con las descripciones de los articulos
        for (var articulosGetIndex in articulosGet) {
            var singleArticuloGet = articulosGet[articulosGetIndex];

            tmpArtDescripcion[singleArticuloGet.value.trim()] = singleArticuloGet.name;
        }

        setArtDescripcion(tmpArtDescripcion);
    };

    try {
        var ejercicioEditorOptions = useMemo(() => {
            return {
                items: ejercicios,
                displayExpr: 'name',
                valueExpr: 'value',
                searchEnabled: false,
                value: (reportPayload.header.Ejercicio ? reportPayload.header.Ejercicio : new Date().getFullYear()),
                readOnly: false
            };
        }, [ejercicios, reportPayload]);
    }
    catch (e) {
        var ejercicioEditorOptions = {
            items: ejercicios,
            displayExpr: 'name',
            valueExpr: 'value',
            searchEnabled: false,
            readOnly: false,
            value: '',
        };
    }

    try {
        var nivelEditorOptions = useMemo(() => {
            return {
                items: niveles,
                displayExpr: 'name',
                valueExpr: 'value',
                searchEnabled: false,
                value: (reportPayload.header.Nivel ? reportPayload.header.Nivel : 'Concentrado'),
                readOnly: false
            };
        }, [niveles, reportPayload]);
    }
    catch (e) {
        var nivelEditorOptions = {
            items: niveles,
            displayExpr: 'name',
            valueExpr: 'value',
            searchEnabled: false,
            readOnly: false,
            value: '',
        };
    }

    try {
        var periodosVentaEditorOptions = useMemo(() => {
            return {
                items: periodosVenta,
                displayExpr: 'name',
                valueExpr: 'value',
                searchEnabled: false,
                value: (reportPayload.header.PeriodosVenta ? reportPayload.header.PeriodosVenta : 'Pasado'),
                readOnly: !allowFuture
            };
        }, [periodosVenta, reportPayload, allowFuture]);
    }
    catch (e) {
        var periodosVentaEditorOptions = {
            items: periodosVenta,
            displayExpr: 'name',
            valueExpr: 'value',
            searchEnabled: false,
            readOnly: !allowFuture,
            value: '',
        };
    }

    try {
        var periodoEditorOptions = useMemo(() => {
            return {
                items: periodos,
                displayExpr: 'name',
                valueExpr: 'value',
                searchEnabled: false,
                value: (reportPayload.header.Periodo ? reportPayload.header.Periodo : new Date().getMonth() + 1),
                readOnly: false
            };
        }, [periodos, reportPayload]);
    }
    catch (e) {
        var periodoEditorOptions = {
            items: periodos,
            displayExpr: 'name',
            valueExpr: 'value',
            searchEnabled: false,
            readOnly: false,
            value: '',
        };
    }

    try {
        var plazaEntregaEditorOptions = useMemo(() => {
            return {
                items: plazasentrega,
                displayExpr: 'name',
                valueExpr: 'value',
                searchEnabled: false,
                value: reportPayload.header.PlazaEntrega,
                readOnly: false,
                showClearButton: true
            };
        }, [plazasentrega, reportPayload]);
    }
    catch (e) {
        var plazaEntregaEditorOptions = {
            items: plazasentrega,
            displayExpr: 'name',
            valueExpr: 'value',
            searchEnabled: false,
            readOnly: false,
            value: '',
            showClearButton: true
        };
    }

    const refreshButton = React.useCallback(async () => {
        if (formReporteRef.current) {
            var formReporte = formReporteRef.current.instance();
            var isValid = formReporte.validate();

            if (!isValid.isValid) {
                return;
            }
        }

        var proveedores = [];

        for (var proveedoresSeleccionadosIndex in proveedoresSeleccionados) {
            proveedores.push({
                Proveedor: proveedoresSeleccionados[proveedoresSeleccionadosIndex],
            })
        };

        if (proveedores.length === 0) {
            setVisibleToast(true);
            setMessageToast('Favor de especificar el Proveedor')
            setTypeToast('error');

            return;
        }

        try {
            setVisibleLoadPanel(true);

            var lambdaFunction;

            if (window.location.href.indexOf('sandbox.') >= 0 || window.location.href.indexOf('localhost') >= 0)
                lambdaFunction = 'reporteCompras-dev';
            else if (window.location.href.indexOf('sandboxtampico.') >= 0)
                lambdaFunction = 'reporteCompras-devleona';
            else if (window.location.href.indexOf('tampico.') >= 0)
                lambdaFunction = 'reporteCompras-prodleona';
            else
                lambdaFunction = 'reporteCompras-prod';

            Auth.currentCredentials()
                .then(credentials => {
                    const lambda = new Lambda({
                        credentials: Auth.essentialCredentials(credentials),
                        region: 'us-east-1'
                    });

                    var params = {
                        FunctionName: lambdaFunction,
                        Payload: JSON.stringify({
                            Ejercicio: reportPayload.header.Ejercicio,
                            Periodo: reportPayload.header.Periodo,
                            Proveedor: proveedores,
                            username: props.username,
                            PlazaEntrega: reportPayload.header.PlazaEntrega,
                            Nivel: reportPayload.header.Nivel,
                            PeriodosVenta: reportPayload.header.PeriodosVenta
                        })
                    };

                    lambda.invoke(params, function (err, data) {
                        if (err) {
                            setVisibleLoadPanel(false);
                        }
                        else {
                            var payload = JSON.parse(JSON.parse(data.Payload).body);

                            // Asignamos las descripciones de los Articulos y los nombres de los Proveedores
                            for (var payloadIndex in payload.detail) {
                                payload.detail[payloadIndex].Nom = provNombre[payload.detail[payloadIndex].Prov.trim()];
                                payload.detail[payloadIndex].Descr = artDescripcion[payload.detail[payloadIndex].Art.trim()];
                            }

                            for (var payloadIndex in payload.detailWHS) {
                                payload.detailWHS[payloadIndex].Descr = artDescripcion[payload.detailWHS[payloadIndex].Art.trim()];
                            }

                            for (var payloadIndex in payload.po) {
                                payload.po[payloadIndex].Descr = artDescripcion[payload.po[payloadIndex].Art.trim()];
                            }

                            setVisibleLoadPanel(false);
                            setReportPayload(payload);

                            if (reportPayload.header.Nivel === 'Concentrado') {
                                setTabSelectedIndex(0)
                            }
                            else if (reportPayload.header.Nivel === 'Desglosado') {
                                setTabSelectedIndex(1)
                            }


                            if (parseInt(reportPayload.header.Periodo) >= 7) {
                                setAllowFuture(true);
                            }
                            else {
                                setAllowFuture(false);
                            }
                    
                            if (parseInt(reportPayload.header.Periodo) < 7) { 
                                setFuture1(false);
                                setFuture2(false);
                                setFuture3(false);
                                setFuture4(false);
                                setFuture5(false);
                                setFuture6(false);
                                setFuture7(false);                    
                            }
                            else if (parseInt(reportPayload.header.Periodo) === 7) {
                                setFuture1(true);
                                setFuture2(true);
                                setFuture3(true);
                                setFuture4(true);
                                setFuture5(true);
                                setFuture6(true);
                                setFuture7(true);
                            }
                            else if (parseInt(reportPayload.header.Periodo) === 8) {
                                setFuture1(true);
                                setFuture2(true);
                                setFuture3(true);
                                setFuture4(true);
                                setFuture5(true);
                                setFuture6(true);
                                setFuture7(false);
                            }
                            else if (parseInt(reportPayload.header.Periodo) === 9) {
                                setFuture1(true);
                                setFuture2(true);
                                setFuture3(true);
                                setFuture4(true);
                                setFuture5(true);
                                setFuture6(false);
                                setFuture7(false);
                            }
                            else if (parseInt(reportPayload.header.Periodo) === 10) {
                                setFuture1(true);
                                setFuture2(true);
                                setFuture3(true);
                                setFuture4(true);
                                setFuture5(false);
                                setFuture6(false);
                                setFuture7(false);
                            }
                            else if (parseInt(reportPayload.header.Periodo) === 11) {
                                setFuture1(true);
                                setFuture2(true);
                                setFuture3(true);
                                setFuture4(false);
                                setFuture5(false);
                                setFuture6(false);
                                setFuture7(false);
                            }
                            else if (parseInt(reportPayload.header.Periodo) === 12) {
                                setFuture1(true);
                                setFuture2(true);
                                setFuture3(false);
                                setFuture4(false);
                                setFuture5(false);
                                setFuture6(false);
                                setFuture7(false);
                            }        
                        }
                    });
                })
        }
        catch (e) {
            setVisibleLoadPanel(false);

            if (e === 'No current user') {
                history.push('/');

                window.location.reload();
            }
        }
    }, [formReporteRef, history, reportPayload, props.username, proveedoresSeleccionados, artDescripcion, provNombre]);

    const cfgItems = React.useCallback(() => {
        var baseUrl = window.location.href.split('/')[0];

        window.open(baseUrl + "/#/ItemPOSuggestion", "_blank");
    }, []);

    const cfgLocations = React.useCallback(() => {
        var baseUrl = window.location.href.split('/')[0];

        window.open(baseUrl + "/#/WarehousePOSuggestion", "_blank");
    }, []);

    const getEjercicios = React.useCallback(() => {
        var year = new Date().getFullYear();
        var yearAux = 2020;
        var ejerciciosTmp = [];

        while (yearAux <= year) {
            ejerciciosTmp.push({
                name: yearAux,
                value: yearAux
            });

            yearAux++;
        }

        setEjercicios(ejerciciosTmp);
    }, []);

    const getPeriodos = React.useCallback(() => {
        var periodosTmp = [];

        periodosTmp.push({
            name: 'Enero',
            value: 1
        });

        periodosTmp.push({
            name: 'Febrero',
            value: 2
        });

        periodosTmp.push({
            name: 'Marzo',
            value: 3
        });

        periodosTmp.push({
            name: 'Abril',
            value: 4
        });

        periodosTmp.push({
            name: 'Mayo',
            value: 5
        });

        periodosTmp.push({
            name: 'Junio',
            value: 6
        });

        periodosTmp.push({
            name: 'Julio',
            value: 7
        });

        periodosTmp.push({
            name: 'Agosto',
            value: 8
        });

        periodosTmp.push({
            name: 'Septiembre',
            value: 9
        });

        periodosTmp.push({
            name: 'Octubre',
            value: 10
        });

        periodosTmp.push({
            name: 'Noviembre',
            value: 11
        });

        periodosTmp.push({
            name: 'Diciembre',
            value: 12
        });


        setPeriodos(periodosTmp);
    }, []);

    const getPlazasEntrega = React.useCallback(async () => {
        let apiName = 'AdminSC';
        let path = '/getcatalogo';

        let myInit = {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
            },
            queryStringParameters: {
                type: 'PlazaEntrega',
                username: JSON.parse(JSON.stringify(await Auth.currentAuthenticatedUser())).username
            }
        }

        var plazasEntregaGet = await API.get(apiName, path, myInit);

        setPlazasEntrega(plazasEntregaGet);

        return plazasEntregaGet;
    }, []);

    const syncDataGridSelection = React.useCallback((e) => {
        setProveedoresSeleccionados(e.value || []);
    }, []);

    const dataGridOnSelectionChanged = React.useCallback((selectedRowKeys) => {
        setProveedoresSeleccionados((selectedRowKeys.selectedRowKeys.length && selectedRowKeys.selectedRowKeys) || []);
    }, []);

    const dataGridRender = React.useCallback(() => (
        <DataGrid
            height={345}
            dataSource={proveedores}
            hoverStateEnabled={true}
            selectedRowKeys={proveedoresSeleccionados}
            onSelectionChanged={dataGridOnSelectionChanged}
            allowUpdating={false}
            keyExpr={"value"}
            key={"value"}
        >
            <Selection mode="multiple" />
            <Scrolling mode="virtual" />
            <Paging
                enabled={true}
                pageSize={10}
            />
            <FilterRow visible={true} />
            <Column dataField="value" caption="Proveedor" width={100} dataType="string" />
            <Column dataField="name" caption="Nombre" width={100} dataType="string" />
        </DataGrid>
    ),
        [proveedores, proveedoresSeleccionados, dataGridOnSelectionChanged],
    );

    useEffect(() => {
        async function initialize() {
            setVisibleLoadPanel(true);

            getEjercicios();
            getPeriodos();
            getProveedor('');
            getPlazasEntrega();
            getArticulo('');

            setVisibleLoadPanel(false);
        }

        initialize();
    }, [getEjercicios, getPeriodos, getPlazasEntrega]);

    function onExporting(e) {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Datos');

        exportDataGrid({
            component: e.component,
            worksheet,
            autoFilterEnabled: true,
        }).then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
                saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Datos.xlsx');
            });
        });
        e.cancel = true;
    };

    const groupCaptionNamedRender = (iconName) => {
        const groupCaptionRender = (data) =>
        (
            <GroupCaption
                iconName={iconName}
                {...data}
            />
        );
        return groupCaptionRender;
    }

    const onRowPrepared = React.useCallback((e) => {
        if (e.rowType === "data" && e.data.Color === 'Azul') {
            e.rowElement.style.backgroundColor = 'lightblue';
        }
        else if (e.rowType === "data" && e.data.Color === 'Rojo') {
            e.rowElement.style.backgroundColor = 'crimson';
        }
        else if (e.rowType === "data" && e.data.Color === 'Verde') {
            e.rowElement.style.backgroundColor = 'lightgreen';
        }
        else if (e.rowType === "data" && e.data.Color === 'Amarillo') {
            e.rowElement.style.backgroundColor = 'lightyellow';
        }
        else if (e.rowType === "data" && e.data.Color === 'Morado') {
            e.rowElement.style.backgroundColor = 'plum';
        }
        else if (e.rowType === "data" && e.data.Color === 'Rosa') {
            e.rowElement.style.backgroundColor = 'pink';
        }
    }, [])

    const onFieldDatosGeneralesChanged = React.useCallback(async (e) => {
        var dataField = e.dataField;
        var value = parseInt(e.value);

        if (formReporteRef.current) {
            var formDatosGenerales = formReporteRef.current.instance();

            if (e.dataField === 'header.Periodo') {
                if (value >= 7) {
                    setAllowFuture(true);
                }
                else {
                    setAllowFuture(false);
                    formDatosGenerales.updateData('header.PeriodosVenta', 'Pasado');
                }

                if (value < 7) { 
                    setFuture1(false);
                    setFuture2(false);
                    setFuture3(false);
                    setFuture4(false);
                    setFuture5(false);
                    setFuture6(false);
                    setFuture7(false);                    
                }
                else if (value === 7) {
                    setFuture1(true);
                    setFuture2(true);
                    setFuture3(true);
                    setFuture4(true);
                    setFuture5(true);
                    setFuture6(true);
                    setFuture7(true);
                }
                else if (value === 8) {
                    setFuture1(true);
                    setFuture2(true);
                    setFuture3(true);
                    setFuture4(true);
                    setFuture5(true);
                    setFuture6(true);
                    setFuture7(false);
                }
                else if (value === 9) {
                    setFuture1(true);
                    setFuture2(true);
                    setFuture3(true);
                    setFuture4(true);
                    setFuture5(true);
                    setFuture6(false);
                    setFuture7(false);
                }
                else if (value === 10) {
                    setFuture1(true);
                    setFuture2(true);
                    setFuture3(true);
                    setFuture4(true);
                    setFuture5(false);
                    setFuture6(false);
                    setFuture7(false);
                }
                else if (value === 11) {
                    setFuture1(true);
                    setFuture2(true);
                    setFuture3(true);
                    setFuture4(false);
                    setFuture5(false);
                    setFuture6(false);
                    setFuture7(false);
                }
                else if (value === 12) {
                    setFuture1(true);
                    setFuture2(true);
                    setFuture3(false);
                    setFuture4(false);
                    setFuture5(false);
                    setFuture6(false);
                    setFuture7(false);
                }
            }
        }
    }, [formReporteRef]);

    const onHidingToast = React.useCallback(() => {
        setVisibleToast(false);
    }, []);

    return (
        <div>
            <div class="bg-gray-200 m-2">
                <h1 class="font-sans inline-block text-xl font-bold text-gray-700 leading-8 align-top mb-1"><FontAwesomeIcon icon={faFileChartColumn} size="xl" /> Sugerencia de Compras</h1>
            </div>
            <div>
                <button onClick={refreshButton} type="submit" class="ml-2 mb-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                    <FontAwesomeIcon icon={faArrowsRotate} /> Actualizar
                </button>

                {poSuggestionsReportCfg.toString() === 'true' ?
                    (
                        <button onClick={cfgItems} type="submit" class="ml-4 mb-4 bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
                            <FontAwesomeIcon icon={faBoxCheck} /> Cfg. Artículos
                        </button>
                    ) : (
                        <div class="inline-block"></div>
                    )}

                {poSuggestionsReportCfg.toString() === 'true' ?
                    (
                        <button onClick={cfgLocations} type="submit" class="ml-4 mb-4 bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
                            <FontAwesomeIcon icon={faWarehouse} /> Cfg. Almacenes
                        </button>
                    ) : (
                        <div class="inline-block"></div>
                    )}
            </div>

            <LoadPanel
                shadingColor="rgba(0,0,0,0.4)"
                visible={visibleLoadPanel}
                showIndicator={true}
                message="Por favor espere..."
                shading={true}
                showPane={true}
                hideOnOutsideClick={false}
            />

            <Toast
                visible={visibleToast}
                message={messageToast}
                type={typeToast}
                onHiding={onHidingToast}
                displayTime={5000}
                width={'auto'}
                position={{
                    my: 'top center',
                    at: 'top center',
                    of: window,
                    offset: '0 130'
                }}
            />

            <div class="m-2 bg-white p-4 shadow-xl">
                <Form
                    id="formReporte"
                    labelMode={'outside'}
                    formData={reportPayload}
                    readOnly={false}
                    showColonAfterLabel={false}
                    labelLocation={'top'}
                    ref={formReporteRef}
                    onFieldDataChanged={onFieldDatosGeneralesChanged}
                >
                    <GroupItem captionRender={groupCaptionNamedRender('filter')} caption="Filtros" colCount={2}>
                        <Item dataField="header.Ejercicio" editorType="dxSelectBox" editorOptions={ejercicioEditorOptions}>
                            <Label text={"Ejercicio"} />
                            <RequiredRule message="Ejercicio es requerido" />
                        </Item>
                        <Item dataField="header.Periodo" editorType="dxSelectBox" editorOptions={periodoEditorOptions}>
                            <Label text={"Periodo"} />
                            <RequiredRule message="Periodo es requerido" />
                        </Item>
                        <SimpleItem dataField="header.Proveedor" caption="Proveedor" >
                            <Label text={"Proveedor"} />
                            <DropDownBox
                                value={proveedoresSeleccionados}
                                valueExpr="value"
                                deferRendering={false}
                                displayExpr="value"
                                showClearButton={true}
                                dataSource={proveedores}
                                onValueChanged={syncDataGridSelection}
                                contentRender={dataGridRender}
                            />
                            <RequiredRule message="Proveedor es requerido" />
                        </SimpleItem>
                        <Item dataField="header.PlazaEntrega" editorType="dxSelectBox" editorOptions={plazaEntregaEditorOptions}>
                            <Label text={"Plaza de Entrega"} />
                            <RequiredRule message="Plaza de Entrega es requerida" />
                        </Item>
                    </GroupItem>
                    <GroupItem captionRender={groupCaptionNamedRender('search')} caption="Nivel" colCount={2}>
                        <Item dataField="header.Nivel" editorType="dxSelectBox" editorOptions={nivelEditorOptions}>
                            <Label text={"Nivel"} />
                            <RequiredRule message="Nivel es requerido" />
                        </Item>
                        <Item dataField="header.PeriodosVenta" editorType="dxSelectBox" editorOptions={periodosVentaEditorOptions} >
                            <Label text={"Periodos Venta"} />
                            <RequiredRule message="Periodos de venta es requerido" />
                        </Item>
                    </GroupItem>
                    <GroupItem captionRender={groupCaptionNamedRender('datausage')} caption="Información" colCount={1}>
                        <TabbedItem>
                            <TabPanelOptions deferRendering={false} showNavButtons={true} selectedIndex={tabSelectedIndex} />
                            <Tab title="Sugerencia por Plaza" colCount={2} visible={reportPayload.header.Nivel === 'Concentrado'}>
                                <DataGrid
                                    height={800}
                                    id="gridSugerenciaPOPlaza"
                                    columnsAutoWidth={true}
                                    filterBuilder={filterBuilder}
                                    dataSource={reportPayload.detail}
                                    allowColumnReordering={true}
                                    allowColumnResizing={true}
                                    columnResizingMode={"widget"}
                                    showBorders={true}
                                    showRowLines={true}
                                    showColumnLines={true}
                                    remoteOperations={{ filtering: true }}
                                    ref={gridSugerenciaRef}
                                    onExporting={onExporting}
                                    onRowPrepared={onRowPrepared}
                                >
                                    <Scrolling rowRenderingMode='virtual'></Scrolling>
                                    <Paging defaultPageSize={10} />
                                    <Pager
                                        visible={true}
                                        allowedPageSizes={allowedPageSizes}
                                        displayMode='compact'
                                        showPageSizeSelector={true}
                                        showInfo={true}
                                        showNavigationButtons={true}
                                    />
                                    <FilterRow visible={true} />
                                    <FilterPanel visible={true} />
                                    <FilterBuilderPopup position={filterBuilderPopupPosition} />

                                    <HeaderFilter visible={true} />

                                    <GroupPanel visible={true} />
                                    <SearchPanel visible={true} />
                                    <ColumnChooser
                                        enabled={true}
                                        mode='select'
                                    >
                                        <ColumnChooserSearch
                                            enabled={true}
                                        />

                                        <ColumnChooserSelection
                                            allowSelectAll={true}
                                            selectByClick={true}
                                            recursive={true}
                                        />
                                    </ColumnChooser>
                                    <Grouping autoExpandAll={autoExpandAll} />
                                    <StateStoring enabled={true} type="localStorage" storageKey="gridContainerPOSuggestion" />
                                    <Export enabled={true} />
                                    <Column dataField="Plaza" dataType="string" width={150} caption="Plaza" />
                                    <Column dataField="Prov" dataType="string" width={110} caption="Proveedor" />
                                    <Column dataField="Nom" dataType="string" width={300} caption="Nombre" />
                                    <Column dataField="Art" dataType="string" width={100} caption="Artículo" />
                                    <Column dataField="Descr" dataType="string" width={300} caption="Descripción" />

                                    <Column dataField="Ants2" dataType="number" width={150} caption={reportPayload.titles.Periodo3Nombre} visible={true} />
                                    <Column dataField="Ants" dataType="number" width={150} caption={reportPayload.titles.Periodo2Nombre} visible={true} />
                                    <Column dataField="Ant" dataType="number" width={150} caption={reportPayload.titles.Periodo1Nombre} visible={true} />
                                    <Column dataField="Act" dataType="number" width={150} caption={reportPayload.titles.PeriodoNombre} visible={true} />
                                    
                                    {(reportPayload.header.PeriodosVenta === 'Pasado' ) && <Column dataField="Ants2_ant" dataType="number" width={150} caption={reportPayload.titles.Periodo3_ant_Nombre} visible={true} /> }
                                    {(reportPayload.header.PeriodosVenta === 'Pasado' ) && <Column dataField="Ants_ant" dataType="number" width={150} caption={reportPayload.titles.Periodo2_ant_Nombre} visible={true} /> }
                                    {(reportPayload.header.PeriodosVenta === 'Pasado' ) && <Column dataField="Ant_ant" dataType="number" width={150} caption={reportPayload.titles.Periodo1_ant_Nombre} visible={true} /> }
                                    {(reportPayload.header.PeriodosVenta === 'Pasado' ) && <Column dataField="Act_ant" dataType="number" width={150} caption={reportPayload.titles.Periodo_ant_Nombre} visible={true} /> }

                                    {(reportPayload.header.PeriodosVenta === 'Futuro' && future1 ) && <Column dataField="VF1" dataType="number" width={150} caption={reportPayload.titles.PFuturoNombre1} visible={true }/> }
                                    {(reportPayload.header.PeriodosVenta === 'Futuro' && future2 ) && <Column dataField="VF2" dataType="number" width={150} caption={reportPayload.titles.PFuturoNombre2} visible={true }/> }
                                    {(reportPayload.header.PeriodosVenta === 'Futuro' && future3 ) && <Column dataField="VF3" dataType="number" width={150} caption={reportPayload.titles.PFuturoNombre3} visible={true }/> }
                                    {(reportPayload.header.PeriodosVenta === 'Futuro' && future4 ) && <Column dataField="VF4" dataType="number" width={150} caption={reportPayload.titles.PFuturoNombre4} visible={true }/> }
                                    {(reportPayload.header.PeriodosVenta === 'Futuro' && future5 ) && <Column dataField="VF5" dataType="number" width={150} caption={reportPayload.titles.PFuturoNombre5} visible={true }/> }
                                    {(reportPayload.header.PeriodosVenta === 'Futuro' && future6 ) && <Column dataField="VF6" dataType="number" width={150} caption={reportPayload.titles.PFuturoNombre6} visible={true }/> }
                                    {(reportPayload.header.PeriodosVenta === 'Futuro' && future7 ) && <Column dataField="VF7" dataType="number" width={150} caption={reportPayload.titles.PFuturoNombre7} visible={true }/> }

                                    <Column dataField="Ex" dataType="number" width={110} caption="Existencia" />
                                    <Column dataField="Ad" dataType="number" width={110} caption="Adeudo" />
                                    <Column dataField="OC" dataType="number" width={110} caption="OC" />
                                    <Column dataField="Patas" dataType="number" width={110} caption="Patas" />
                                    <Column dataField="Color" dataType="string" width={200} caption="Color Seguimiento" visible={false} />
                                    <Summary>
                                        <GroupItem
                                            column="Act"
                                            summaryType="sum"
                                            valueFormat=",##0"
                                            displayFormat="{0}"
                                            showInGroupFooter={false}>
                                        </GroupItem>
                                        <GroupItem
                                            column="Ant"
                                            summaryType="sum"
                                            valueFormat=",##0"
                                            displayFormat="{0}"
                                            showInGroupFooter={false}>
                                        </GroupItem>
                                        <GroupItem
                                            column="Ants"
                                            summaryType="sum"
                                            valueFormat=",##0"
                                            displayFormat="{0}"
                                            showInGroupFooter={false}>
                                        </GroupItem>
                                        <GroupItem
                                            column="Ants2"
                                            summaryType="sum"
                                            valueFormat=",##0"
                                            displayFormat="{0}"
                                            showInGroupFooter={false}>
                                        </GroupItem>
                                        <GroupItem
                                            column="Act_ant"
                                            summaryType="sum"
                                            valueFormat=",##0"
                                            displayFormat="{0}"
                                            showInGroupFooter={false}>
                                        </GroupItem>
                                        <GroupItem
                                            column="Ant_ant"
                                            summaryType="sum"
                                            valueFormat=",##0"
                                            displayFormat="{0}"
                                            showInGroupFooter={false}>
                                        </GroupItem>
                                        <GroupItem
                                            column="Ants_ant"
                                            summaryType="sum"
                                            valueFormat=",##0"
                                            displayFormat="{0}"
                                            showInGroupFooter={false}>
                                        </GroupItem>
                                        <GroupItem
                                            column="Ants2_ant"
                                            summaryType="sum"
                                            valueFormat=",##0"
                                            displayFormat="{0}"
                                            showInGroupFooter={false}>
                                        </GroupItem>
                                        <GroupItem
                                            column="Ex"
                                            summaryType="sum"
                                            valueFormat=",##0"
                                            displayFormat="{0}"
                                            showInGroupFooter={false}>
                                        </GroupItem>
                                        <GroupItem
                                            column="Ad"
                                            summaryType="sum"
                                            valueFormat=",##0"
                                            displayFormat="{0}"
                                            showInGroupFooter={false}>
                                        </GroupItem>
                                        <GroupItem
                                            column="OC"
                                            summaryType="sum"
                                            valueFormat=",##0"
                                            displayFormat="{0}"
                                            showInGroupFooter={false}>
                                        </GroupItem>
                                        <GroupItem
                                            column="Patas"
                                            summaryType="sum"
                                            valueFormat=",##0"
                                            displayFormat="{0}"
                                            showInGroupFooter={false}>
                                        </GroupItem>

                                        <GroupItem
                                            column="VF1"
                                            summaryType="sum"
                                            valueFormat=",##0"
                                            displayFormat="{0}"
                                            showInGroupFooter={false}>
                                        </GroupItem>
                                        <GroupItem
                                            column="VF2"
                                            summaryType="sum"
                                            valueFormat=",##0"
                                            displayFormat="{0}"
                                            showInGroupFooter={false}>
                                        </GroupItem>
                                        <GroupItem
                                            column="VF3"
                                            summaryType="sum"
                                            valueFormat=",##0"
                                            displayFormat="{0}"
                                            showInGroupFooter={false}>
                                        </GroupItem>
                                        <GroupItem
                                            column="VF4"
                                            summaryType="sum"
                                            valueFormat=",##0"
                                            displayFormat="{0}"
                                            showInGroupFooter={false}>
                                        </GroupItem>
                                        <GroupItem
                                            column="VF5"
                                            summaryType="sum"
                                            valueFormat=",##0"
                                            displayFormat="{0}"
                                            showInGroupFooter={false}>
                                        </GroupItem>
                                        <GroupItem
                                            column="VF6"
                                            summaryType="sum"
                                            valueFormat=",##0"
                                            displayFormat="{0}"
                                            showInGroupFooter={false}>
                                        </GroupItem>
                                        <GroupItem
                                            column="VF7"
                                            summaryType="sum"
                                            valueFormat=",##0"
                                            displayFormat="{0}"
                                            showInGroupFooter={false}>
                                        </GroupItem>

                                        <TotalItem
                                            column="Act"
                                            summaryType="sum"
                                            valueFormat=",##0"
                                            displayFormat="{0}"
                                            showInGroupFooter={false}>
                                        </TotalItem>
                                        <TotalItem
                                            column="Ant"
                                            summaryType="sum"
                                            valueFormat=",##0"
                                            displayFormat="{0}"
                                            showInGroupFooter={false}>
                                        </TotalItem>
                                        <TotalItem
                                            column="Ants"
                                            summaryType="sum"
                                            valueFormat=",##0"
                                            displayFormat="{0}"
                                            showInGroupFooter={false}>
                                        </TotalItem>
                                        <TotalItem
                                            column="Ants2"
                                            summaryType="sum"
                                            valueFormat=",##0"
                                            displayFormat="{0}"
                                            showInGroupFooter={false}>
                                        </TotalItem>
                                        <TotalItem
                                            column="Act_ant"
                                            summaryType="sum"
                                            valueFormat=",##0"
                                            displayFormat="{0}"
                                            showInGroupFooter={false}>
                                        </TotalItem>
                                        <TotalItem
                                            column="Ant_ant"
                                            summaryType="sum"
                                            valueFormat=",##0"
                                            displayFormat="{0}"
                                            showInGroupFooter={false}>
                                        </TotalItem>
                                        <TotalItem
                                            column="Ants_ant"
                                            summaryType="sum"
                                            valueFormat=",##0"
                                            displayFormat="{0}"
                                            showInGroupFooter={false}>
                                        </TotalItem>
                                        <TotalItem
                                            column="Ants2_ant"
                                            summaryType="sum"
                                            valueFormat=",##0"
                                            displayFormat="{0}"
                                            showInGroupFooter={false}>
                                        </TotalItem>
                                        <TotalItem
                                            column="Ex"
                                            summaryType="sum"
                                            valueFormat=",##0"
                                            displayFormat="{0}"
                                            showInGroupFooter={false}>
                                        </TotalItem>
                                        <TotalItem
                                            column="Ad"
                                            summaryType="sum"
                                            valueFormat=",##0"
                                            displayFormat="{0}"
                                            showInGroupFooter={false}>
                                        </TotalItem>
                                        <TotalItem
                                            column="OC"
                                            summaryType="sum"
                                            valueFormat=",##0"
                                            displayFormat="{0}"
                                            showInGroupFooter={false}>
                                        </TotalItem>
                                        <TotalItem
                                            column="Patas"
                                            summaryType="sum"
                                            valueFormat=",##0"
                                            displayFormat="{0}"
                                            showInGroupFooter={false}>
                                        </TotalItem>

                                        <TotalItem
                                            column="VF1"
                                            summaryType="sum"
                                            valueFormat=",##0"
                                            displayFormat="{0}"
                                            showInGroupFooter={false}>
                                        </TotalItem>
                                        <TotalItem
                                            column="VF2"
                                            summaryType="sum"
                                            valueFormat=",##0"
                                            displayFormat="{0}"
                                            showInGroupFooter={false}>
                                        </TotalItem>
                                        <TotalItem
                                            column="VF3"
                                            summaryType="sum"
                                            valueFormat=",##0"
                                            displayFormat="{0}"
                                            showInGroupFooter={false}>
                                        </TotalItem>
                                        <TotalItem
                                            column="VF4"
                                            summaryType="sum"
                                            valueFormat=",##0"
                                            displayFormat="{0}"
                                            showInGroupFooter={false}>
                                        </TotalItem>
                                        <TotalItem
                                            column="VF5"
                                            summaryType="sum"
                                            valueFormat=",##0"
                                            displayFormat="{0}"
                                            showInGroupFooter={false}>
                                        </TotalItem>
                                        <TotalItem
                                            column="VF6"
                                            summaryType="sum"
                                            valueFormat=",##0"
                                            displayFormat="{0}"
                                            showInGroupFooter={false}>
                                        </TotalItem>
                                        <TotalItem
                                            column="VF7"
                                            summaryType="sum"
                                            valueFormat=",##0"
                                            displayFormat="{0}"
                                            showInGroupFooter={false}>
                                        </TotalItem>
                                    </Summary>

                                    <Sorting mode="none" />
                                    <FilterRow visible={true} />
                                    <FilterPanel visible={true} />
                                    <FilterBuilderPopup position={filterBuilderPopupPosition} />

                                    <HeaderFilter visible={true} />
                                </DataGrid>
                            </Tab>
                            <Tab title="Sugerencia por Almacén" colCount={2} visible={reportPayload.header.Nivel === 'Desglosado'}>
                                <DataGrid
                                    height={800}
                                    id="gridSugerenciaAlm"
                                    columnsAutoWidth={true}
                                    dataSource={reportPayload.detailWHS}
                                    allowColumnReordering={true}
                                    allowColumnResizing={true}
                                    columnResizingMode={"widget"}
                                    showBorders={true}
                                    showRowLines={true}
                                    showColumnLines={true}
                                    remoteOperations={{ filtering: true }}
                                    ref={gridSugerenciaAlmRef}
                                    onExporting={onExporting}
                                    onRowPrepared={onRowPrepared}
                                >
                                    <Scrolling
                                        mode='virtual'
                                    />
                                    <Paging defaultPageSize={10} />
                                    <Pager
                                        visible={true}
                                        allowedPageSizes={allowedPageSizes}
                                        displayMode='compact'
                                        showPageSizeSelector={true}
                                        showInfo={true}
                                        showNavigationButtons={true}
                                    />
                                    <FilterRow visible={true} />
                                    <FilterPanel visible={true} />
                                    <FilterBuilderPopup position={filterBuilderPopupPosition} />

                                    <HeaderFilter visible={true} />

                                    <GroupPanel visible={true} />
                                    <SearchPanel visible={true} />
                                    <ColumnChooser
                                        enabled={true}
                                        mode='select'
                                    >
                                        <ColumnChooserSearch
                                            enabled={true}
                                        />

                                        <ColumnChooserSelection
                                            allowSelectAll={true}
                                            selectByClick={true}
                                            recursive={true}
                                        />
                                    </ColumnChooser>
                                    <Grouping autoExpandAll={autoExpandAll} />
                                    <StateStoring enabled={true} type="localStorage" storageKey="gridContainerPOWHSSuggestion" />
                                    <Export enabled={true} />
                                    <Column dataField="Plaza" dataType="string" width={150} caption="Plaza" />
                                    <Column dataField="Alm" dataType="string" width={150} caption="Almacén" />
                                    <Column dataField="Art" dataType="string" width={100} caption="Artículo" />
                                    <Column dataField="Descr" dataType="string" width={300} caption="Descripción" />

                                    <Column dataField="Ants2" dataType="number" width={150} caption={reportPayload.titles.Periodo3Nombre} visible={true} />
                                    <Column dataField="Ants" dataType="number" width={150} caption={reportPayload.titles.Periodo2Nombre} visible={true} />
                                    <Column dataField="Ant" dataType="number" width={150} caption={reportPayload.titles.Periodo1Nombre} visible={true} />
                                    <Column dataField="Act" dataType="number" width={150} caption={reportPayload.titles.PeriodoNombre} visible={true} />
                                    
                                    {(reportPayload.header.PeriodosVenta === 'Pasado' ) && <Column dataField="Ants2_ant" dataType="number" width={150} caption={reportPayload.titles.Periodo3_ant_Nombre} visible={true} /> }
                                    {(reportPayload.header.PeriodosVenta === 'Pasado' ) && <Column dataField="Ants_ant" dataType="number" width={150} caption={reportPayload.titles.Periodo2_ant_Nombre} visible={true} /> }
                                    {(reportPayload.header.PeriodosVenta === 'Pasado' ) && <Column dataField="Ant_ant" dataType="number" width={150} caption={reportPayload.titles.Periodo1_ant_Nombre} visible={true} /> }
                                    {(reportPayload.header.PeriodosVenta === 'Pasado' ) && <Column dataField="Act_ant" dataType="number" width={150} caption={reportPayload.titles.Periodo_ant_Nombre} visible={true} /> }

                                    {(reportPayload.header.PeriodosVenta === 'Futuro' && future1 ) && <Column dataField="VF1" dataType="number" width={150} caption={reportPayload.titles.PFuturoNombre1} visible={true }/> }
                                    {(reportPayload.header.PeriodosVenta === 'Futuro' && future2 ) && <Column dataField="VF2" dataType="number" width={150} caption={reportPayload.titles.PFuturoNombre2} visible={true }/> }
                                    {(reportPayload.header.PeriodosVenta === 'Futuro' && future3 ) && <Column dataField="VF3" dataType="number" width={150} caption={reportPayload.titles.PFuturoNombre3} visible={true }/> }
                                    {(reportPayload.header.PeriodosVenta === 'Futuro' && future4 ) && <Column dataField="VF4" dataType="number" width={150} caption={reportPayload.titles.PFuturoNombre4} visible={true }/> }
                                    {(reportPayload.header.PeriodosVenta === 'Futuro' && future5 ) && <Column dataField="VF5" dataType="number" width={150} caption={reportPayload.titles.PFuturoNombre5} visible={true }/> }
                                    {(reportPayload.header.PeriodosVenta === 'Futuro' && future6 ) && <Column dataField="VF6" dataType="number" width={150} caption={reportPayload.titles.PFuturoNombre6} visible={true }/> }
                                    {(reportPayload.header.PeriodosVenta === 'Futuro' && future7 ) && <Column dataField="VF7" dataType="number" width={150} caption={reportPayload.titles.PFuturoNombre7} visible={true }/> }


                                    <Column dataField="Ex" dataType="number" width={110} caption="Existencia" />
                                    <Column dataField="Ad" dataType="number" width={110} caption="Adeudo" />
                                    <Column dataField="OC" dataType="number" width={110} caption="OC" />
                                    <Column dataField="Color" dataType="string" width={200} caption="Color Seguimiento" visible={false} />
                                    <Column dataField="Patas" dataType="number" width={110} caption="Patas" />

                                    <Summary>
                                        <GroupItem
                                            column="Act"
                                            summaryType="sum"
                                            valueFormat=",##0"
                                            displayFormat="{0}"
                                            showInGroupFooter={false}>
                                        </GroupItem>
                                        <GroupItem
                                            column="Ant"
                                            summaryType="sum"
                                            valueFormat=",##0"
                                            displayFormat="{0}"
                                            showInGroupFooter={false}>
                                        </GroupItem>
                                        <GroupItem
                                            column="Ants"
                                            summaryType="sum"
                                            valueFormat=",##0"
                                            displayFormat="{0}"
                                            showInGroupFooter={false}>
                                        </GroupItem>
                                        <GroupItem
                                            column="Ants2"
                                            summaryType="sum"
                                            valueFormat=",##0"
                                            displayFormat="{0}"
                                            showInGroupFooter={false}>
                                        </GroupItem>
                                        <GroupItem
                                            column="Act_ant"
                                            summaryType="sum"
                                            valueFormat=",##0"
                                            displayFormat="{0}"
                                            showInGroupFooter={false}>
                                        </GroupItem>
                                        <GroupItem
                                            column="Ant_ant"
                                            summaryType="sum"
                                            valueFormat=",##0"
                                            displayFormat="{0}"
                                            showInGroupFooter={false}>
                                        </GroupItem>
                                        <GroupItem
                                            column="Ants_ant"
                                            summaryType="sum"
                                            valueFormat=",##0"
                                            displayFormat="{0}"
                                            showInGroupFooter={false}>
                                        </GroupItem>
                                        <GroupItem
                                            column="Ants2_ant"
                                            summaryType="sum"
                                            valueFormat=",##0"
                                            displayFormat="{0}"
                                            showInGroupFooter={false}>
                                        </GroupItem>
                                        <GroupItem
                                            column="Ex"
                                            summaryType="sum"
                                            valueFormat=",##0"
                                            displayFormat="{0}"
                                            showInGroupFooter={false}>
                                        </GroupItem>
                                        <GroupItem
                                            column="Ad"
                                            summaryType="sum"
                                            valueFormat=",##0"
                                            displayFormat="{0}"
                                            showInGroupFooter={false}>
                                        </GroupItem>
                                        <GroupItem
                                            column="OC"
                                            summaryType="sum"
                                            valueFormat=",##0"
                                            displayFormat="{0}"
                                            showInGroupFooter={false}>
                                        </GroupItem>
                                        <GroupItem
                                            column="Patas"
                                            summaryType="sum"
                                            valueFormat=",##0"
                                            displayFormat="{0}"
                                            showInGroupFooter={false}>
                                        </GroupItem>
                                        <GroupItem
                                            column="VF1"
                                            summaryType="sum"
                                            valueFormat=",##0"
                                            displayFormat="{0}"
                                            showInGroupFooter={false}>
                                        </GroupItem>
                                        <GroupItem
                                            column="VF2"
                                            summaryType="sum"
                                            valueFormat=",##0"
                                            displayFormat="{0}"
                                            showInGroupFooter={false}>
                                        </GroupItem>
                                        <GroupItem
                                            column="VF3"
                                            summaryType="sum"
                                            valueFormat=",##0"
                                            displayFormat="{0}"
                                            showInGroupFooter={false}>
                                        </GroupItem>
                                        <GroupItem
                                            column="VF4"
                                            summaryType="sum"
                                            valueFormat=",##0"
                                            displayFormat="{0}"
                                            showInGroupFooter={false}>
                                        </GroupItem>
                                        <GroupItem
                                            column="VF5"
                                            summaryType="sum"
                                            valueFormat=",##0"
                                            displayFormat="{0}"
                                            showInGroupFooter={false}>
                                        </GroupItem>
                                        <GroupItem
                                            column="VF6"
                                            summaryType="sum"
                                            valueFormat=",##0"
                                            displayFormat="{0}"
                                            showInGroupFooter={false}>
                                        </GroupItem>
                                        <GroupItem
                                            column="VF7"
                                            summaryType="sum"
                                            valueFormat=",##0"
                                            displayFormat="{0}"
                                            showInGroupFooter={false}>
                                        </GroupItem>

                                        <TotalItem
                                            column="Act"
                                            summaryType="sum"
                                            valueFormat=",##0"
                                            displayFormat="{0}"
                                            showInGroupFooter={false}>
                                        </TotalItem>
                                        <TotalItem
                                            column="Ant"
                                            summaryType="sum"
                                            valueFormat=",##0"
                                            displayFormat="{0}"
                                            showInGroupFooter={false}>
                                        </TotalItem>
                                        <TotalItem
                                            column="Ants"
                                            summaryType="sum"
                                            valueFormat=",##0"
                                            displayFormat="{0}"
                                            showInGroupFooter={false}>
                                        </TotalItem>
                                        <TotalItem
                                            column="Ants2"
                                            summaryType="sum"
                                            valueFormat=",##0"
                                            displayFormat="{0}"
                                            showInGroupFooter={false}>
                                        </TotalItem>
                                        <TotalItem
                                            column="Act_ant"
                                            summaryType="sum"
                                            valueFormat=",##0"
                                            displayFormat="{0}"
                                            showInGroupFooter={false}>
                                        </TotalItem>
                                        <TotalItem
                                            column="Ant_ant"
                                            summaryType="sum"
                                            valueFormat=",##0"
                                            displayFormat="{0}"
                                            showInGroupFooter={false}>
                                        </TotalItem>
                                        <TotalItem
                                            column="Ants_ant"
                                            summaryType="sum"
                                            valueFormat=",##0"
                                            displayFormat="{0}"
                                            showInGroupFooter={false}>
                                        </TotalItem>
                                        <TotalItem
                                            column="Ants2_ant"
                                            summaryType="sum"
                                            valueFormat=",##0"
                                            displayFormat="{0}"
                                            showInGroupFooter={false}>
                                        </TotalItem>
                                        <TotalItem
                                            column="Ex"
                                            summaryType="sum"
                                            valueFormat=",##0"
                                            displayFormat="{0}"
                                            showInGroupFooter={false}>
                                        </TotalItem>
                                        <TotalItem
                                            column="Ad"
                                            summaryType="sum"
                                            valueFormat=",##0"
                                            displayFormat="{0}"
                                            showInGroupFooter={false}>
                                        </TotalItem>
                                        <TotalItem
                                            column="OC"
                                            summaryType="sum"
                                            valueFormat=",##0"
                                            displayFormat="{0}"
                                            showInGroupFooter={false}>
                                        </TotalItem>
                                        <TotalItem
                                            column="Patas"
                                            summaryType="sum"
                                            valueFormat=",##0"
                                            displayFormat="{0}"
                                            showInGroupFooter={false}>
                                        </TotalItem>

                                        <TotalItem
                                            column="VF1"
                                            summaryType="sum"
                                            valueFormat=",##0"
                                            displayFormat="{0}"
                                            showInGroupFooter={false}>
                                        </TotalItem>
                                        <TotalItem
                                            column="VF2"
                                            summaryType="sum"
                                            valueFormat=",##0"
                                            displayFormat="{0}"
                                            showInGroupFooter={false}>
                                        </TotalItem>
                                        <TotalItem
                                            column="VF3"
                                            summaryType="sum"
                                            valueFormat=",##0"
                                            displayFormat="{0}"
                                            showInGroupFooter={false}>
                                        </TotalItem>
                                        <TotalItem
                                            column="VF4"
                                            summaryType="sum"
                                            valueFormat=",##0"
                                            displayFormat="{0}"
                                            showInGroupFooter={false}>
                                        </TotalItem>
                                        <TotalItem
                                            column="VF5"
                                            summaryType="sum"
                                            valueFormat=",##0"
                                            displayFormat="{0}"
                                            showInGroupFooter={false}>
                                        </TotalItem>
                                        <TotalItem
                                            column="VF6"
                                            summaryType="sum"
                                            valueFormat=",##0"
                                            displayFormat="{0}"
                                            showInGroupFooter={false}>
                                        </TotalItem>
                                        <TotalItem
                                            column="VF7"
                                            summaryType="sum"
                                            valueFormat=",##0"
                                            displayFormat="{0}"
                                            showInGroupFooter={false}>
                                        </TotalItem>
                                    </Summary>

                                    <Sorting mode="none" />
                                    <FilterRow visible={true} />
                                    <FilterPanel visible={true} />
                                    <FilterBuilderPopup position={filterBuilderPopupPosition} />

                                    <HeaderFilter visible={true} />
                                </DataGrid>
                            </Tab>
                            <Tab title="O.C." colCount={2} visible={reportPayload.header.Nivel === 'Desglosado'}>
                                <DataGrid
                                    height={800}
                                    id="gridSugerenciaOC"
                                    columnsAutoWidth={true}
                                    dataSource={reportPayload.po}
                                    allowColumnReordering={true}
                                    allowColumnResizing={true}
                                    columnResizingMode={"widget"}
                                    showBorders={true}
                                    showRowLines={true}
                                    showColumnLines={true}
                                    remoteOperations={{ filtering: true }}
                                    ref={gridSugerenciaAlmRef}
                                    onExporting={onExporting}
                                    onRowPrepared={onRowPrepared}
                                >
                                    <Scrolling
                                        mode='virtual'
                                    />
                                    <Paging defaultPageSize={10} />
                                    <Pager
                                        visible={true}
                                        allowedPageSizes={allowedPageSizes}
                                        displayMode='compact'
                                        showPageSizeSelector={true}
                                        showInfo={true}
                                        showNavigationButtons={true}
                                    />
                                    <FilterRow visible={true} />
                                    <FilterPanel visible={true} />
                                    <FilterBuilderPopup position={filterBuilderPopupPosition} />

                                    <HeaderFilter visible={true} />

                                    <GroupPanel visible={true} />
                                    <SearchPanel visible={true} />
                                    <ColumnChooser
                                        enabled={true}
                                        mode='select'
                                    >
                                        <ColumnChooserSearch
                                            enabled={true}
                                        />

                                        <ColumnChooserSelection
                                            allowSelectAll={true}
                                            selectByClick={true}
                                            recursive={true}
                                        />
                                    </ColumnChooser>
                                    <Grouping autoExpandAll={autoExpandAll} />
                                    <StateStoring enabled={true} type="localStorage" storageKey="gridContainerPOPOSuggestion" />
                                    <Export enabled={true} />

                                    <Column dataField="Plaza" dataType="string" width={150} caption="Plaza" />

                                    <Column dataField="Mov" dataType="string" width={150} caption="Movimiento" />
                                    <Column dataField="MovID" dataType="string" width={150} caption="Folio" />
                                    <Column dataField="Fecha" dataType="date" width={150} caption="Fecha" />
                                    <Column dataField="Art" dataType="string" width={150} caption="Artículo" />
                                    <Column dataField="Descr" dataType="string" width={150} caption="Descripción" />
                                    <Column dataField="Cant" dataType="number" width={150} caption="Cantidad" />
                                    <Column dataField="Alm" dataType="string" width={150} caption="Almacén" />

                                    <Sorting mode="none" />
                                    <FilterRow visible={true} />
                                    <FilterPanel visible={true} />
                                    <FilterBuilderPopup position={filterBuilderPopupPosition} />

                                    <HeaderFilter visible={true} />
                                </DataGrid>
                            </Tab>
                        </TabbedItem>
                    </GroupItem>
                </Form>
            </div>
        </div>
    );
}